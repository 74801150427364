import React, { memo, useCallback } from "react";
import styles from "./styles.module.scss";
import { useLocation, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { modals } from "@mantine/modals";

const LoginButton = memo(({ className }) => {
  const { t } = useTranslation();
  const { push } = useHistory();

  const onClickButton = useCallback(() => {
    push("/login");
    modals.closeAll();
  }, [push]);

  return (
    <button
      className={clsx([styles.loginBtn, styles[className]])}
      onClick={onClickButton}
    >
      {t("login")}
    </button>
  );
});

export default LoginButton;
