import React from "react";

import { Flex, Text } from "@mantine/core";

const EmptyNotice = ({ text, icon, color }) => {
  return (
    <Flex direction="column" align="center" gap="5px" pt="13px" pb="13px">
      {icon}

      <Text color={color} size="md">
        {text}
      </Text>
    </Flex>
  );
};
export default EmptyNotice;
