import React from "react";

import { Flex, Text } from "@mantine/core";

import styles from "./styles.module.scss";

const ListModal = ({ innerProps }) => {
  return (
    <Flex justify="flex-start">
      <Flex
        gap="6px"
        direction="column"
        align="flex-start"
        className={styles.modalGroup}
      >
        {Object.entries(innerProps.renderData).map(([key, value]) => (
          <Text>
            <span className={styles.weightText}>{key}</span>: {value}
          </Text>
        ))}
      </Flex>
    </Flex>
  );
};

export default ListModal;
