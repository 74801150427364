import React from "react";

const UseAvatar = () => {
  const avatarUrls = [
    "/assets/images/avatars/bear1.jpg",
    "/assets/images/avatars/bear2.jpg",
    "/assets/images/avatars/bear3.jpg",
    "/assets/images/avatars/bear4.jpg",
    "/assets/images/avatars/bear5.jpg",
    "/assets/images/avatars/bear6.jpg",
    "/assets/images/avatars/bear7.jpg",
    "/assets/images/avatars/bear8.jpg",
    "/assets/images/avatars/bear9.jpg",
    "/assets/images/avatars/bear10.jpg",
  ];

  return {
    avatarPath: avatarUrls[Math.floor(Math.random() * avatarUrls.length)],
  };
};

export default UseAvatar;
