import React, { useState, useRef } from "react";
import { Table, createStyles, rem, Box, Tooltip, Flex } from "@mantine/core";

import styles from "./styles.module.scss";
import clsx from "clsx";

import ScrollAreaLayout from "../../../widgets/ScrollAreaLayout";
import EmptyNotice from "../EmptyNotice";
import { IconVideoOff } from "@tabler/icons-react";

const VideoTableModal = ({ innerProps }) => {
  const theadMainRef = useRef(null);

  const [scrolled, setScrolled] = useState(false);

  const useStyles = createStyles((theme) => ({
    header: {
      position: "sticky",
      top: 0,
      backgroundColor:
        theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white,
      transition: "box-shadow 150ms ease",

      "&::after": {
        content: '""',
        position: "absolute",
        left: 0,
        right: 0,
        bottom: 0,
        borderBottom: `${rem(1)} solid ${
          theme.colorScheme === "dark"
            ? theme.colors.dark[3]
            : theme.colors.gray[2]
        }`,
      },
    },
    bgTBody: {
      backgroundColor:
        theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white,
    },
    bgTHead: {
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[6]
          : theme.colors.gray[0],
    },
    bgWrapper: {
      background:
        theme.colorScheme === "dark"
          ? theme.colors.cyan[9]
          : theme.colors.grape[9],
    },
    scrolled: {
      boxShadow: theme.shadows.sm,
    },
  }));

  const { classes, cx } = useStyles();

  return (
    <div className={styles.modal}>
      <Box className={styles.tableWrapper}>
        {!innerProps?.data?.length ? (
          <Flex
            justify="center"
            align="center"
            className={styles.emptyNoticeBlock}
          >
            <EmptyNotice
              icon={<IconVideoOff size="25px" color="black" />}
              text="So far, no purchased videos"
              color="black"
            />
          </Flex>
        ) : (
          <ScrollAreaLayout setScrolled={setScrolled} height={450}>
            <Table
              withColumnBorders
              withBorder
              className={clsx([styles.table])}
            >
              <thead
                className={clsx([
                  cx({
                    [classes.scrolled]: scrolled,
                  }),
                  classes.bgTHead,
                ])}
                ref={theadMainRef}
              >
                <tr>
                  <th>Video id</th>
                  <th>Video title</th>
                  <th colSpan={2}>Your advance</th>
                </tr>
              </thead>
              <thead
                className={clsx([classes.bgTHead])}
                style={{ top: theadMainRef?.current?.clientHeight }}
              >
                <tr>
                  <th></th>
                  <th></th>
                  <th>Amount</th>
                  <th>Paid</th>
                </tr>
              </thead>

              <tbody className={clsx([classes.bgTBody])}>
                {innerProps?.data?.map((obj) => (
                  <tr key={obj.videoId}>
                    <td>{obj.videoId}</td>
                    <td
                      onClick={() =>
                        window.open(`/library/${obj.videoId}`, "_blank")
                      }
                      className={clsx(styles.hover)}
                    >
                      <Tooltip.Floating label={obj.videoTitle}>
                        <p>
                          {obj.videoTitle.length <= 25
                            ? obj.videoTitle
                            : `${obj.videoTitle.substring(0, 25)}...`}
                        </p>
                      </Tooltip.Floating>
                    </td>
                    <td>
                      {obj.advanceData.amount}
                      <span className={clsx([styles.gray])}> $</span>
                    </td>
                    <td>{obj.advanceData.received}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </ScrollAreaLayout>
        )}
      </Box>
    </div>
  );
};

export default VideoTableModal;
