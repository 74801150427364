import React, { memo, useCallback, useState } from "react";

import { Button, Textarea } from "@mantine/core";

import styles from "./styles.module.scss";

const CommentModal = memo(({ innerProps }) => {
  const [text, setText] = useState("");
  const [isRequested, setIsRequested] = useState(false);

  const onSubmit = useCallback(
    async (event) => {
      try {
        setIsRequested(true);
        event.preventDefault();

        if (innerProps.hasOwnProperty("onSubmit")) {
          await innerProps.onSubmit(text);
        }
      } catch (err) {
        console.log(err);
      } finally {
        setIsRequested(false);
      }
    },
    [innerProps, text, setIsRequested]
  );

  const onChangeInput = useCallback(
    async (event) => {
      setText(event.target.value);
    },
    [setText]
  );

  return (
    <div className={styles.dialogWindowByEnterValue}>
      <form onSubmit={onSubmit}>
        <Textarea
          value={text}
          onChange={onChangeInput}
          className={styles.searchVideoInput}
          autosize
          placeholder="Required field"
        />
        <Button
          gradient={{ from: "grape", to: "cyan", deg: 90 }}
          variant="gradient"
          className={styles.submitBtn}
          type="submit"
          disabled={!text}
          loading={isRequested}
        >
          Send
        </Button>
      </form>
    </div>
  );
});

export default CommentModal;
