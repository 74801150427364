import { Button, Flex, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { modals } from "@mantine/modals";
import React, { memo, useCallback, useState } from "react";

const OauthCodeModal = memo(({ innerProps }) => {
  const [isRequested, setIsRequested] = useState(false);

  const codeForm = useForm({
    initialValues: {
      code: "",
    },

    validate: {
      code: (value) => (!value ? "Empty field" : null),
    },
  });

  const onSubmitForm = useCallback(
    async (values) => {
      try {
        setIsRequested(true);

        if (innerProps.hasOwnProperty("onSubmit")) {
          await innerProps.onSubmit(values.code);
        }
      } catch (err) {
        console.log(err);
      } finally {
        setIsRequested(false);
        modals.closeAll();
      }
    },
    [innerProps, setIsRequested, codeForm]
  );

  const onDisabledBtn = useCallback(async () => {
    return !codeForm.values.code || isRequested;
  }, [codeForm.values]);

  return (
    <form onSubmit={codeForm.onSubmit(onSubmitForm)}>
      <Flex direction="column" gap="sm">
        <TextInput
          {...codeForm.getInputProps("code")}
          disabled={isRequested}
          w="100%"
        />
        <Button
          loading={isRequested}
          color="grape"
          type="submit"
          disabled={onDisabledBtn}
          w="100%"
        >
          Send
        </Button>
      </Flex>
    </form>
  );
});

export default OauthCodeModal;
