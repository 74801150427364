export const definingPathForUser = (currentUser) => {
  switch (currentUser?.role) {
    case "author":
      return "/licensors";
    case "researcher":
      return "/researcher";
    case "admin":
      return "/admin";
    case "editor":
      return "/publishing";
    case "stringer":
      return "/stringer";
    case "company":
      return "/company";
    default:
  }
};

export const definingTextOfMenuItem = (currentUser) => {
  switch (currentUser?.role) {
    case "author":
      return "View sales dashboard";
    case "researcher":
      return "Personal area";
    case "admin":
      return "Admin panel";
    case "editor":
      return "Personal area";
    case "stringer":
      return "Personal area";
    case "company":
      return "Personal area";
    default:
  }
};
