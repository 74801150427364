import React from 'react';
import { PriorityHighRounded } from '@material-ui/icons';

import styles from './toastWarning.module.scss';

const ToastWarning = ({ text }) => {
  return (
    <div className={styles.toast}>
      <div className={styles.iconBlock}>
        <PriorityHighRounded style={{ color: '#fff' }} />
      </div>
      <p>{text}</p>
    </div>
  );
};

export default ToastWarning;
