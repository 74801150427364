import React, { useState, useCallback } from "react";

import { TextInput, Flex, Button, List } from "@mantine/core";

import toast from "react-hot-toast";

import axiosInstance from "../../../api/axios.instance";

import ToastWarning from "../../../toast/components/toastWarning/ToastWarning";

import styles from "./styles.module.scss";
import { modals } from "@mantine/modals";

const PaymentModal = ({ innerProps }) => {
  const [isRequested, setIsRequested] = useState(false);
  const [freeValue, setFreeValue] = useState("");

  const definePayloadForCommitRequest = useCallback(() => {
    const paymentVariants = [];

    let amountData = {};

    if (innerProps.hasOwnProperty("salaryData")) {
      paymentVariants.push(...innerProps?.salaryData?.paymentVariants);
      amountData = innerProps.salaryData.amount;
    }

    if (!!freeValue) {
      paymentVariants.push("extra");
      amountData = {
        ...amountData,
        total: amountData.hasOwnProperty("total")
          ? amountData.total + +freeValue
          : freeValue,
        extra: +freeValue,
      };
    }

    return {
      paymentVariants,
      amountData,
    };
  }, [innerProps, freeValue]);

  const onMakePayment = useCallback(async () => {
    try {
      setIsRequested(true);
      let data;

      if (innerProps.companyId) {
        const res = await axiosInstance.post(
            `/payment/commit`,
            definePayloadForCommitRequest(),
            {
              params: {
                companyId: innerProps.companyId,
              },
            }
        );
        data = res.data;

      } else {
        const res = await axiosInstance.post(
            `/payment/commit`,
            definePayloadForCommitRequest(),
            {
              params: {
                userId: innerProps.userId
              },
            }
        );
        data = res.data;
      }


      switch (data.status) {
        case "warning":
          toast.custom(<ToastWarning text={data.message} />);
          break;
        case "success":
          modals.closeAll();
          if (innerProps.hasOwnProperty("onTrigger")) {
            innerProps.onTrigger();
          }
          toast.success(data.message);
          setFreeValue("");
          break;
      }
    } catch (err) {
      console.log(err);
      toast.error("Server side error");
    } finally {
      setIsRequested(false);
    }
  }, [
    setIsRequested,
    innerProps,
    setFreeValue,
    axiosInstance,
    definePayloadForCommitRequest,
  ]);

  const onDefineBtnText = useCallback(() => {
    if (Boolean(freeValue)) {
      return (
        <Button
          onClick={() => onMakePayment("balance")}
          color="cyan"
          variant="outline"
          loading={isRequested}
          type="submit"
          w="100%"
        >
          {`Pay $${freeValue} (it will be added to the balance)`}
        </Button>
      );
    } else {
      return innerProps.hasOwnProperty("salaryData") &&
        !!innerProps?.salaryData?.amount?.total ? (
        <Flex w="100%" direction="column" gap="sm" align="flex-start">
          <Button
            loading={isRequested}
            onClick={onMakePayment}
            color="grape"
            variant="filled"
            type="submit"
            w="100%"
          >
            Pay ${innerProps.salaryData.amount.total}
          </Button>
          <List size="xs">
            {innerProps.salaryData.description.map((val) => (
              <List.Item key={val}>{val}</List.Item>
            ))}
          </List>
        </Flex>
      ) : null;
    }
  }, [innerProps, freeValue, onMakePayment, isRequested]);

  const toSubmitForm = useCallback((event) => {
    event.preventDefault();
  }, []);

  return (
    <form className={styles.formationPaymentForm} onSubmit={toSubmitForm}>
      <Flex direction="column" gap="sm" align="flex-start" w="100%">
        <TextInput
          className={styles.input}
          placeholder="Free entry"
          disabled={isRequested}
          value={freeValue}
          icon="$"
          type="number"
          onChange={(event) => {
            setFreeValue(event.target.value);
          }}
        />
        {onDefineBtnText()}
      </Flex>
    </form>
  );
};

export default PaymentModal;
