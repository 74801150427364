import React, { useRef } from 'react';
import { Skeleton } from '@material-ui/lab';
import { Table } from '@mantine/core';
import styles from './styles.module.scss';

const SkeletonTable = ({ columnCount, rowCount, tableSkeletonRef }) => {
  return (
    <Table
      ref={tableSkeletonRef}
      withColumnBorders
      className={styles.skeletonTable}
    >
      <thead>
        <tr>
          {Array.from({ length: columnCount }).map((value, index) => (
            <th key={index}>
              <Skeleton
                height={14}
                style={{ width: '80%' }}
                className={styles.skeleton}
              />
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {Array.from({ length: rowCount })?.map((value, index) => (
          <tr key={index}>
            {Array.from({ length: columnCount })?.map((value, index) => (
              <td key={index}>
                <Skeleton
                  height={14}
                  style={{ width: '80%' }}
                  className={styles.skeleton}
                />
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default SkeletonTable;
