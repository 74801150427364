import React, { memo, useCallback } from "react";
import styles from "./styles.module.scss";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { modals } from "@mantine/modals";
import Cookies from "js-cookie";

const LogoutButton = memo(({ setCurrentUser }) => {
  const { t } = useTranslation();

  const onClickButton = useCallback(() => {
    Cookies.remove("vb-access-token");
    Cookies.remove("vb-refresh-token");
    setCurrentUser(null);
    modals.closeAll();
  }, [setCurrentUser]);

  return (
    <button className={clsx([styles.btn])} onClick={onClickButton}>
      {t("logout")}
    </button>
  );
});

export default LogoutButton;
