export const menuItems = [
  { label: "home", path: "/" },
  { label: "submitVideo", path: "/submitVideo" },
  { label: "videoLibrary", path: "/library" },
  { label: "aboutUs", path: "/aboutUs" },
  {
    label: "contactUs",
    path: "/contactUs",
  },
];
