import axios from "axios";
import Cookies from "js-cookie";

const baseUrl = process.env.REACT_APP_SERVER_BASE_URL;

const axiosInstance = axios.create({
  baseURL: baseUrl,
});

axiosInstance.interceptors.request.use(
  async (request) => {
    const accessToken = Cookies.get("vb-access-token");

    if (accessToken) {
      request.headers["Authorization"] = "Bearer " + accessToken;
    }
    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (config) => {
    return config;
  },
  async (err) => {
    const originalRequest = err.config;

    if (
      err.response?.data?.code === 401 &&
      Cookies.get("vb-access-token") &&
      Cookies.get("vb-refresh-token")
      // err.config &&
      // !err.config._isRetry
    ) {
      try {
        originalRequest._isRetry = true;
        const { data } = await axios.post(`${baseUrl}/auth/refresh`, {
          refreshToken: Cookies.get("vb-refresh-token"),
        });

        Cookies.set("vb-access-token", data.newAccessToken);
        return axiosInstance.request(originalRequest);
      } catch (err) {
        console.log(err);
        Cookies.remove("vb-refresh-token");
        Cookies.remove("vb-access-token");
        window.location.assign("/login");
      }
    }
    throw err;
  }
);

export default axiosInstance;
