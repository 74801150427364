import React from 'react'

import Cookie from 'js-cookie'

import { useLocation } from 'react-router-dom'

import { Route, Redirect } from 'react-router-dom'

const PrivateRoute = ({ component: Component, handleChildFunc, ...rest }) => {
  const accessToken = Cookie.get('vb-access-token')

  const { pathname } = useLocation()

  return (
    <Route
      {...rest}
      render={(props) =>
        accessToken ||
        pathname.includes('licensors') ||
        pathname.includes('submit') ? (
          <Component
            {...props}
            accessToken={accessToken}
            handleChildFunc={handleChildFunc}
          />
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  )
}

export default PrivateRoute
