import { Avatar, Flex, Menu, Text } from "@mantine/core";
import React, { memo, useCallback } from "react";
import { definingPathForUser, definingTextOfMenuItem } from "../../../../utils";
import Cookies from "js-cookie";
import {
  IconLogout,
  IconVideo,
  IconUser,
  IconSettings,
} from "@tabler/icons-react";
import { useLocation, Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.scss";

const AppActionsMenu = memo(({ currentUser, setCurrentUser, randomAvatar }) => {
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const { push } = useHistory();

  const onRedirect = useCallback(() => {
    push(definingPathForUser(currentUser));
  }, [definingPathForUser, currentUser]);

  const onLogout = useCallback(() => {
    Cookies.remove("vb-access-token");
    Cookies.remove("vb-refresh-token");
    setCurrentUser(null);
  }, [setCurrentUser]);

  return (
    <Menu shadow="md" width={170} className={styles.menu}>
      <Menu.Target>
        <Flex align="center" gap="md">
          <Text fz={12} fw={500}>
            {currentUser?.name}
          </Text>
          <Avatar
            src={
              !!currentUser?.avatarUrl ? currentUser.avatarUrl : randomAvatar
            }
          />
        </Flex>
      </Menu.Target>

      <Menu.Dropdown>
        {/*<Menu.Item disabled icon={<IconSettings color="#5decff" size={15} />}>*/}
        {/*  Account settings*/}
        {/*</Menu.Item>*/}
        <Menu.Label>Application</Menu.Label>
        <Menu.Divider />
        {currentUser &&
          definingPathForUser(currentUser) &&
          pathname !== definingPathForUser(currentUser) && (
            <Menu.Item
              onClick={onRedirect}
              icon={<IconUser color="#5decff" size={15} />}
            >
              {definingTextOfMenuItem(currentUser)}
            </Menu.Item>
          )}
        {currentUser?.role === "admin" && pathname !== "/publishing" && (
          <Menu.Item
            onClick={() => push("/publishing")}
            icon={<IconVideo color="#5decff" size={15} />}
          >
            Publications panel
          </Menu.Item>
        )}

        {currentUser?.role === "company" && pathname !== "/adding" && (
            <Menu.Item
                onClick={() => push("/adding")}
                icon={<IconVideo color="#5decff" size={15} />}
            >
              Add panel
            </Menu.Item>
        )}

        <Menu.Item
          onClick={onLogout}
          icon={<IconLogout color="#5decff" size={15} />}
        >
          {t("logout")}
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
});

export default AppActionsMenu;
