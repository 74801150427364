import React, { useCallback, useState } from "react";
import { Button } from "@mantine/core";

const ConfirmModal = ({ innerProps }) => {
  const [isRequested, setIsRequested] = useState(false);

  const onConfirm = useCallback(async () => {
    setIsRequested(true);
    try {
      await innerProps.onConfirm();
    } catch (error) {
    } finally {
      setIsRequested(false);
    }
  }, [innerProps]);

  return (
    <div style={{ display: "flex", justifyContent: "flex-end", marginTop: 20 }}>
      <Button
        variant="outline"
        onClick={innerProps.onClose}
        disabled={isRequested}
      >
        Cancel
      </Button>
      <Button
        color="cyan"
        onClick={onConfirm}
        style={{ marginLeft: 10 }}
        loading={isRequested}
        disabled={isRequested}
      >
        Confirm
      </Button>
    </div>
  );
};

export default ConfirmModal;
