import React, { memo, useCallback, useState } from "react";

import { Button, Flex, NumberInput } from "@mantine/core";

import styles from "./styles.module.scss";

const NumberModal = memo(({ innerProps }) => {
  const [value, setValue] = useState("");
  const [isRequested, setIsRequested] = useState(false);

  const onChangeInput = useCallback(
    async (inputValue) => {
      setValue(inputValue);
    },
    [setValue]
  );

  const onSubmit = useCallback(
    async (event) => {
      try {
        setIsRequested(true);
        event.preventDefault();
        if (innerProps.hasOwnProperty("onSubmit")) {
          await innerProps.onSubmit(value);
        }
      } catch (err) {
        console.log(err);
      } finally {
        setIsRequested(false);
      }
    },
    [innerProps, value, setIsRequested]
  );

  return (
    <div className={styles.dialogWindowByEnterValue}>
      <form onSubmit={onSubmit}>
        <Flex direction="column" gap="sm">
          <NumberInput
            value={value}
            onChange={onChangeInput}
            autosize
            placeholder="a number from 1 to 100"
            icon={innerProps?.icon}
            hideControls
            min={0}
            max={100}
            disabled={isRequested}
          />
          <Button
            gradient={{ from: "grape", to: "cyan", deg: 90 }}
            variant="gradient"
            type="submit"
            loading={isRequested}
            disabled={isRequested}
          >
            Send
          </Button>
        </Flex>
      </form>
    </div>
  );
});

export default NumberModal;
