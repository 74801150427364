import React from "react";

import { ScrollArea } from "@mantine/core";

import styles from "./styles.module.scss";

import clsx from "clsx";

const ScrollAreaLayout = ({ children, setScrolled, height }) => {
  return (
    <ScrollArea.Autosize
      mah={height}
      mx="auto"
      maw="100%"
      onScrollPositionChange={({ y }) => setScrolled(y !== 0)}
      className={clsx([styles.scrollArea])}
    >
      {children}
    </ScrollArea.Autosize>
  );
};

export default ScrollAreaLayout;
