import React, { memo, useEffect, useState } from "react";

import { Redirect, Route, Switch, withRouter } from "react-router-dom";

import PrivateRoute from "./routes/PrivateRoute";

import { Suspense } from "react";

import Header from "./components/Header/Header.jsx";
import Footer from "./components/Footer/Footer.jsx";

import {
  NotFoundPageAsync,
  AdminPageAsync,
  ContactPageAsync,
  HomePageAsync,
  LibraryPageAsync,
  LicensorPageAsync,
  LoginPageAsync,
  PermissionNonExclusivePageAsync,
  PermissionNonExclusiveRusPageAsync,
  PrivatePolicyPageAsync,
  PublishingPageAsync,
  QuestionnairePageAsync,
  ResearcherPageAsync,
  CompanyPageAsync,
  StringerPageAsync,
  SubmitVideoPageAsync,
  SubmitVideoRefPageAsync,
  SubmitVideoRefPaidPageAsync,
  TeamPageAsync,
  TermsOfUsePageAsync,
  VideoPageAsync,
  CompletedAgreementPageAsync,
} from "./pages/index.jsx";

import "./styles/common.scss";

import axiosInstance from "./api/axios.instance";
import UseAvatar from "./shared/lib/hooks/useRandomAvatar.jsx";
import VideoCompilationModal from "./shared/ui/VideoCompilationModal";

const App = memo(() => {
  const [currentUser, setCurrentUser] = useState(null);
  const [randomAvatar, setRandomAvatar] = useState(null);

  const { avatarPath } = UseAvatar();

  useEffect(() => {
    (async () => {
      try {
        const { data } = await axiosInstance.post("/auth/authenticate");

        if (data.status === "success") {
          setCurrentUser(data.apiData);
          setRandomAvatar(avatarPath);
        }
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  return (
    <div className="wrapper">
      <Header
        currentUser={currentUser}
        setCurrentUser={setCurrentUser}
        randomAvatar={randomAvatar}
      />
      <section className="mainBlock">
        <Suspense fallback={<></>}>
          <Switch>
            <Route exact path="/" component={HomePageAsync} />

            <Route exact path="/aboutUs" component={TeamPageAsync} />
            <Route exact path="/contactUs" component={ContactPageAsync} />

            <Route exact path="/submitVideo" component={SubmitVideoPageAsync} />
            <Route
              exact
              path="/submitVideo/:name"
              component={SubmitVideoRefPageAsync}
            />
            <Route
              exact
              path="/submitVideos/:refHash"
              component={SubmitVideoRefPaidPageAsync}
            />

            <Route
              exact
              path="/privatePolicy"
              component={PrivatePolicyPageAsync}
            />
            <Route exact path="/termsOfUse" component={TermsOfUsePageAsync} />
            <Route
              exact
              path="/permission"
              component={PermissionNonExclusivePageAsync}
            />
            <Route
              exact
              path="/completedAgreement"
              component={CompletedAgreementPageAsync}
            />
            <Route
              exact
              path="/permissionRus"
              component={PermissionNonExclusiveRusPageAsync}
            />

            <PrivateRoute
              path="/publishing"
              exact={true}
              component={PublishingPageAsync}
            />

            <PrivateRoute
                path="/adding"
                exact={true}
                component={VideoCompilationModal}
            />

            <PrivateRoute
              path="/stringer"
              exact={true}
              component={StringerPageAsync}
            />

            <PrivateRoute
              path="/admin"
              exact={true}
              component={AdminPageAsync}
            />

            <PrivateRoute
              path={"/researcher"}
              exact={true}
              component={ResearcherPageAsync}
            />

            <PrivateRoute
                path={"/company"}
                exact={true}
                component={CompanyPageAsync}
            />

            <PrivateRoute
              path="/licensors"
              exact={true}
              component={LicensorPageAsync}
            />

            <Route exact path="/library" component={LibraryPageAsync} />

            <Route
              exact
              path="/login"
              render={() => (
                <LoginPageAsync
                  setCurrentUser={setCurrentUser}
                  setRandomAvatar={setRandomAvatar}
                />
              )}
            />

            <Route
              exact
              path="/questionnaire"
              component={QuestionnairePageAsync}
            />
            <Route exact path="/library/:id" component={VideoPageAsync} />

            <Route
              exact
              path="/termsOfUsePayment"
              component={TermsOfUsePageAsync}
            />
            <Route exact path="*" component={NotFoundPageAsync} />
            <Redirect from="" to="/" />
          </Switch>
        </Suspense>
      </section>
      <section className="footerBlock">
        <Footer />
      </section>
    </div>
  );
});

export default withRouter(App);
