import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import * as serviceWorker from "./serviceWorker";
import App from "./App";
import ScrollToTop from "./components/scroll-to-top/ScrollToTop";
import "./i18n";
import { Toaster } from "react-hot-toast";
import ThemeProvider from "./providers/ThemeProvider";
import { Notifications } from "@mantine/notifications";
import { ModalsProvider } from "@mantine/modals";
import { MantineProvider } from "@mantine/core";
import UserInfoModal from "./components/AdminPage/UserInfoModal";
import PaymentModal from "./shared/ui/PaymentModal/PaymentModal";
import VideoTableModal from "./shared/ui/VideoTableModal";
import SaleTableModal from "./shared/ui/SaleTableModal";
import QueueVideoModal from "./shared/ui/QueueVideoModal";
import ListModal from "./shared/ui/ListModal";
import CommentModal from "./shared/ui/CommentModal";
import NumberModal from "./shared/ui/NumberModal";
import FeedsManageModal from "./shared/ui/FeedsManageModal";
import VideoCompilationModal from "./shared/ui/VideoCompilationModal";
import MobileMenuModal from "./shared/ui/MobileMenuModal";
import OauthCodeModal from "./shared/ui/OauthCodeModal";
import UserProvider from "./providers/UserProvider";
import UnpaidUnitModal from "./shared/ui/UnpaidUnitsModal";
import { AppProvider } from "./AppContext";
import ConfirmModal from "./shared/ui/ConfirmModal/ConfirmModal";
import { Provider } from "react-redux";
import store from "./app/store";

const toastOptions = {
  success: {
    style: {
      background: "rgb(249, 249, 249)",
      border: "1px solid rgb(193, 190, 183)",
      boxShadow: "unset",
      color: "black",
      zIndex: 10000,
    },
  },
  error: {
    style: {
      background: "rgb(249, 249, 249)",
      border: "1px solid rgb(193, 190, 183)",
      boxShadow: "unset",
      color: "black",
      zIndex: 10000,
    },
  },
  loading: {
    style: {
      background: "rgb(249, 249, 249)",
      border: "1px solid rgb(193, 190, 183)",
      boxShadow: "unset",
      color: "black",
      zIndex: 10000,
    },
  },
};

export const StoreContext = React.createContext();

const modals = {
  workerEditModal: UserInfoModal,
  paymentModal: PaymentModal,
  videoTableModal: VideoTableModal,
  saleTableModal: SaleTableModal,
  queueVideo: QueueVideoModal,
  listModal: ListModal,
  commentModal: CommentModal,
  confirmModal: ConfirmModal,
  numberModal: NumberModal,
  feedsManageModal: FeedsManageModal,
  videoCompilationModal: VideoCompilationModal,
  mobileMenuModal: MobileMenuModal,
  oauthCodeModal: OauthCodeModal,
  unpaidUnitsModal: UnpaidUnitModal,
};

ReactDOM.render(
  <MantineProvider>
    <ThemeProvider>
      <Router>
        <AppProvider>
          <ModalsProvider
            modals={modals}
            labels={{ confirm: "Submit", cancel: "Cancel" }}
            modalProps={{
              overlayProps: {
                backgroundOpacity: 0.2,
                blur: 6,
              },
              centered: true,
              "aria-label": "12px",
            }}
          >
            <Notifications />
            <ScrollToTop />
            <Toaster toastOptions={toastOptions} />
            <Provider store={store}>
              <App />
            </Provider>
          </ModalsProvider>
        </AppProvider>
      </Router>
    </ThemeProvider>
  </MantineProvider>,
  document.getElementById("root")
);

serviceWorker.unregister();
