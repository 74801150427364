import { ActionIcon, Flex } from "@mantine/core";
import React, { memo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import { ReactComponent as Logo } from "../../../shared/assets/images/logo.svg";
import { ReactComponent as InstagramIcon } from "../../../shared/assets/images/instagram.svg";
import { ReactComponent as YoutubeIcon } from "../../../shared/assets/images/youtube.svg";

import styles from "./styles.module.scss";

import { menuItems } from "../../../consts/menuItems";

import clsx from "clsx";
import { modals } from "@mantine/modals";
import LoginButton from "../../../widgets/LoginButton";

import { definingPathForUser, definingTextOfMenuItem } from "../../../utils";
import LogoutButton from "../../../widgets/LogoutButton";

const MobileMenuModal = memo(({ innerProps }) => {
  const { pathname } = useLocation();

  const { t } = useTranslation();

  const filteredMenuItems = menuItems.filter((el) => el.path !== pathname);

  const onClickItem = useCallback(() => {
    modals.closeAll();
  }, [modals]);

  const currentUser = useCallback(innerProps?.currentUser, [innerProps]);
  const setCurrentUser = useCallback(innerProps?.setCurrentUser, [innerProps]);

  return (
    <div className={styles.modal}>
      <Flex
        direction="column"
        align="center"
        gap="xl"
        className={styles.itemsBlock}
      >
        <Link onClick={onClickItem} to="/">
          <Logo className={clsx("logo", styles.logo)} />
        </Link>
        {!!currentUser &&
          definingPathForUser(currentUser) &&
          pathname !== definingPathForUser(currentUser) && (
            <Link
              className={clsx([styles.menuItem, styles.colored])}
              to={definingPathForUser(currentUser)}
              onClick={onClickItem}
            >
              {definingTextOfMenuItem(currentUser)}
            </Link>
          )}
        {currentUser?.role === "admin" && pathname !== "/publishing" && (
          <Link
            className={clsx([styles.menuItem, styles.colored])}
            to={"/publishing"}
            onClick={onClickItem}
          >
            Publications panel
          </Link>
        )}
        {filteredMenuItems.map(({ label, path }) => (
          <Link
            onClick={onClickItem}
            key={path}
            to={path}
            className={styles.menuItem}
          >
            {t(label)}
          </Link>
        ))}

        {!!currentUser ? (
          <LogoutButton setCurrentUser={setCurrentUser} />
        ) : pathname !== "/login" ? (
          <LoginButton className="widthFull" />
        ) : null}
      </Flex>
      <Flex direction="column" gap="sm" align="center">
        <Link
          to="#"
          onClick={(e) => {
            window.location.href = "mailto:Licensing@viralbear.media";
            e.preventDefault();
          }}
        >
          Licensing@viralbear.media
        </Link>
        <Flex align="center" gap="sm">
          <a href="https://www.instagram.com/viralbearr" target="_blank">
            <ActionIcon className={styles.link}>
              <InstagramIcon className={styles.svg} fontSize="large" />
            </ActionIcon>
          </a>
          <a href="https://www.youtube.com/@viralbearrrr" target="_blank">
            <ActionIcon className={styles.link}>
              <YoutubeIcon className={styles.svg} fontSize="large" />
            </ActionIcon>
          </a>
        </Flex>
      </Flex>
    </div>
  );
});

export default MobileMenuModal;
