import React, { memo, useCallback, useState } from "react";

import { withRouter } from "react-router-dom";
import { useTheme, useMediaQuery } from "@material-ui/core";
import { ReactComponent as Logo } from "../../shared/assets/images/logo.svg";
import { ReactComponent as BurgerSvg } from "../../shared/assets/images/burger.svg";

import classNames from "classnames";
import { Link, useLocation, useHistory } from "react-router-dom";

import styles from "./Header.module.scss";
import { ActionIcon, Avatar } from "@mantine/core";

import { menuItems } from "../../consts/menuItems";

import { useTranslation } from "react-i18next";

import AppActionsMenu from "./components/AppActionsMenu";

import clsx from "clsx";

import { openContextModal } from "@mantine/modals";
import LoginButton from "../../widgets/LoginButton";

const Header = memo(({ currentUser, setCurrentUser, randomAvatar }) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { push } = useHistory();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const filteredMenuItems = menuItems.filter((el) => el.path !== pathname);

  const onOpenMobileMenu = useCallback(() => {
    openContextModal({
      modal: "mobileMenuModal",
      fullScreen: true,
      radius: 0,
      transitionProps: { transition: "slide-left", duration: 500 },
      title: !!currentUser ? (
        <Avatar
          size="md"
          src={!!currentUser.avatarUrl ? currentUser.avatarUrl : randomAvatar}
          radius="xl"
        />
      ) : null,
      innerProps: {
        currentUser,
        setCurrentUser,
        randomAvatar,
      },
      closeButtonProps: { size: "30px" },
    });
  }, [currentUser, setCurrentUser]);

  return (
    <header className={styles.header}>
      <div className={clsx(["container", styles.flexContainer])}>
        <div className={styles.headerLeftBlock}>
          <Link to="/">
            <Logo className={classNames("logo", styles.logo)} />
          </Link>
        </div>

        <div className={styles.headerCenterBlock}>
          {filteredMenuItems.map(({ label, path }) => (
            <Link key={path} to={path}>
              {t(label)}
            </Link>
          ))}
        </div>

        <div className={styles.headerRightBlock}>
          {isMobile && (
            <ActionIcon
              onClick={onOpenMobileMenu}
              className={styles.burgerAction}
            >
              <BurgerSvg />
            </ActionIcon>
          )}
          {!isMobile && !currentUser && pathname !== "/login" && (
            <LoginButton className="widthAuto" />
          )}
          {!isMobile && currentUser && (
            <AppActionsMenu
              currentUser={currentUser}
              setCurrentUser={setCurrentUser}
              randomAvatar={randomAvatar}
            />
          )}
        </div>
      </div>
    </header>
  );
});

export default withRouter(Header);

{
  /* <LanguagesMenu
        options={[
          { value: "en", label: "En" },
          { value: "cn", label: "Ch" },
        ]}
      /> */
}

{
  /* {isMobile && (
        <div className={styles.footer_links}>
          <Link to={"/contactUs"} className={styles.contact_us_link}>
            {t("contactUs")}
          </Link>
          <Link
            to="#"
            onClick={(e) => {
              window.location.href = "mailto:Licensing@viralbear.media";
              e.preventDefault();
            }}
          >
            Licensing@viralbear.media
          </Link>
          <div className={styles.social_links}>
            <a href="https://www.instagram.com/viralbearr" target="_blank">
              <IconButton className={styles.link}>
                <InstagramIcon fontSize="large" />
              </IconButton>
            </a>
            <a href="https://www.youtube.com/@viralbearrrr" target="_blank">
              <IconButton className={styles.link}>
                <YoutubeIcon fontSize="large" />
              </IconButton>
            </a>
          </div>
        </div>
      )}
     */
}
