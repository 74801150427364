import React, { useState, useEffect } from "react";

import axiosInstance from "../../../api/axios.instance";

import { useForm } from "@mantine/form";

import clsx from "clsx";

import {
  TextInput,
  Select,
  NumberInput,
  Button,
  Checkbox,
  Grid,
} from "@mantine/core";

import styles from "./styles.module.scss";

import ToastWarning from "../../../toast/components/toastWarning/ToastWarning";

import toast from "react-hot-toast";
import { modals } from "@mantine/modals";

const UserInfoModal = ({ innerProps }) => {
  const userDataForm = useForm({
    initialValues: {
      name: "",
      email: "",
      ...(innerProps?.mode !== "edit" && {
        password: "",
      }),
      nickname: "",
      role: innerProps?.role || "",
      position: "",
      percentage: 0,
      advancePayment: 0,
      advancePaymentNonExclusive: 0,
      country: "",
      telegramId: "",
      paymentMethod: "",
      canBeAssigned: false,
      displayOnTheSite: false,
      videoPool: true,
      phoneBankTransfer: "",
      emailBankTransfer: "",
      addressBankTransfer: "",
      zipCodeBankTransfer: "",
      bankNameBankTransfer: "",
      fullNameBankTransfer: "",
      accountNumberBankTransfer: "",
      AdditionalInformation: "",
      payPalEmail: "",
      textFieldOther: "",
      stringerPaymentType: "",
      stringerPaymentValue: "",
    },

    validate: {
      email: (value) => (!value ? "Empty field" : null),
      name: (value) => (!value ? "Empty field" : null),
      role: (value) => (!value ? "Empty field" : null),
      nickname: (value) =>
        value.includes("@") ? 'Unnecessary character "@"' : null,
      phoneBankTransfer: (value) =>
        userDataForm.values.paymentMethod === "bankTransfer" && !value
          ? "Empty field"
          : null,
      emailBankTransfer: (value) =>
        userDataForm.values.paymentMethod === "bankTransfer" && !value
          ? "Empty field"
          : userDataForm.values.paymentMethod === "bankTransfer" &&
            !/^\S+@\S+$/.test(value)
          ? "Invalid email"
          : null,
      addressBankTransfer: (value) =>
        userDataForm.values.paymentMethod === "bankTransfer" && !value
          ? "Empty field"
          : null,
      zipCodeBankTransfer: (value) =>
        userDataForm.values.paymentMethod === "bankTransfer" && !value
          ? "Empty field"
          : null,
      bankNameBankTransfer: (value) =>
        userDataForm.values.paymentMethod === "bankTransfer" && !value
          ? "Empty field"
          : null,
      fullNameBankTransfer: (value) =>
        userDataForm.values.paymentMethod === "bankTransfer" && !value
          ? "Empty field"
          : null,
      accountNumberBankTransfer: (value) =>
        userDataForm.values.paymentMethod === "bankTransfer" && !value
          ? "Empty field"
          : null,
      payPalEmail: (value) =>
        userDataForm.values.paymentMethod === "payPal" && !value
          ? "Empty field"
          : userDataForm.values.paymentMethod === "payPal" &&
            !/^\S+@\S+$/.test(value)
          ? "Invalid email"
          : null,
      textFieldOther: (value) =>
        userDataForm.values.paymentMethod === "other" && !value
          ? "Empty field"
          : null,
      stringerPaymentType: (value) =>
        !!userDataForm.values.stringerPaymentValue && !value
          ? "Nothing is selected"
          : null,
      stringerPaymentValue: (value) =>
        !!userDataForm.values.stringerPaymentType && !value
          ? "Empty field"
          : null,
    },
  });

  const roleVariants = [
    "researcher",
    "admin",
    "editor",
    "stringer",
    "author",
    "observer",
  ];

  const paymentVariants = [
    {
      value: "bankTransfer",
      label: "Bank Transfer",
      disabled: userDataForm.values.role === "author" ? false : true,
    },
    {
      value: "payPal",
      label: "PayPal",
      disabled: userDataForm.values.role === "author" ? false : true,
    },
    { value: "other", label: "Other" },
  ];

  const [isLoading, setIsLoading] = useState(false);
  const [countries, setCountries] = useState([]);

  useEffect(() => {
    if (innerProps?.userData) {
      userDataForm.setValues({
        ...(innerProps?.userData.name && {
          name: innerProps?.userData.name,
        }),
        ...(innerProps?.userData.email && {
          email: innerProps?.userData.email,
        }),

        ...(innerProps?.userData.position && {
          position: innerProps?.userData.position,
        }),
        ...(innerProps?.userData.role && {
          role: innerProps?.userData.role,
        }),
        ...(innerProps?.userData.country && {
          country: innerProps?.userData.country,
        }),
        ...(innerProps?.userData.percentage && {
          percentage: innerProps?.userData.percentage,
        }),
        ...(innerProps?.userData.telegramId && {
          telegramId: innerProps?.userData.telegramId,
        }),
        ...(innerProps?.userData.advancePayment && {
          advancePayment: innerProps?.userData.advancePayment,
        }),
        ...(innerProps?.userData.advancePaymentNonExclusive && {
          advancePaymentNonExclusive:
            innerProps?.userData.advancePaymentNonExclusive,
        }),
        ...(innerProps?.userData?.nickname && {
          nickname: innerProps.userData.nickname.replace("@", ""),
        }),
        ...(innerProps?.userData?.hasOwnProperty("paymentAsStringer") && {
          stringerPaymentType: innerProps.userData.paymentAsStringer.type,
          stringerPaymentValue: innerProps.userData.paymentAsStringer.amount,
        }),
        ...(innerProps?.userData?.paymentInfo?.variant && {
          ...(innerProps?.userData.paymentInfo.variant === "payPal" && {
            paymentMethod: innerProps?.userData?.paymentInfo?.variant,
            payPalEmail: innerProps?.userData?.paymentInfo?.payPalEmail,
          }),
          ...(innerProps?.userData.paymentInfo.variant === "bankTransfer" && {
            paymentMethod: innerProps?.userData.paymentInfo.variant,
            phoneBankTransfer: innerProps?.userData?.paymentInfo.phoneNumber,
            emailBankTransfer: innerProps?.userData.paymentInfo?.email,
            addressBankTransfer: innerProps?.userData.paymentInfo.address,
            zipCodeBankTransfer: innerProps?.userData.paymentInfo.zipCode,
            bankNameBankTransfer: innerProps?.userData.paymentInfo.bankName,
            fullNameBankTransfer: innerProps?.userData.paymentInfo.fullName,
            accountNumberBankTransfer: innerProps?.userData.paymentInfo.iban,
            AdditionalInformation:
              innerProps?.userData.paymentInfo.additionalInformation,
          }),
          ...(innerProps?.userData.paymentInfo.variant === "other" && {
            paymentMethod: innerProps?.userData?.paymentInfo?.variant,
            textFieldOther: innerProps?.userData?.paymentInfo?.value,
          }),
        }),

        ...(typeof innerProps?.userData.canBeAssigned === "boolean" && {
          canBeAssigned: innerProps?.userData.canBeAssigned,
        }),
        ...(typeof innerProps?.userData.displayOnTheSite === "boolean" && {
          displayOnTheSite: innerProps?.userData.displayOnTheSite,
        }),
        ...(typeof innerProps?.userData.videoPool === "boolean" && {
          videoPool: innerProps?.userData.videoPool,
        }),
      });
    }

    (async () => {
      try {
        const { data } = await axiosInstance.get("/location/getAllCountries");

        setCountries(data.apiData);
      } catch (err) {
        console.log(err);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [innerProps]);

  const toCreateOrUpdateUser = async (values) => {
    setIsLoading(true);
    try {
      let response;

      if (innerProps?.mode === "edit") {
        if (innerProps.role === "company") {
          const { data } = await axiosInstance.patch(
            `/companies/${innerProps?.userData._id}`,
            {
              email: values.email,
              name: values.name,
              percentage: values.percentage,
              ...(values.paymentMethod === "other" && {
                paymentInfo: {
                  variant: values.paymentMethod,
                  value: values.textFieldOther,
                },
              }),
              ...(values.paymentMethod === "bankTransfer" && {
                paymentInfo: {
                  variant: values.paymentMethod,
                  phoneNumber: values.phoneBankTransfer,
                  email: values.emailBankTransfer,
                  address: values.addressBankTransfer,
                  zipCode: values.zipCodeBankTransfer,
                  bankName: values.bankNameBankTransfer,
                  fullName: values.fullNameBankTransfer,
                  iban: values.accountNumberBankTransfer,
                  AdditionalInformation: values.AdditionalInformation,
                },
              }),
              ...(values.paymentMethod === "payPal" && {
                paymentInfo: {
                  variant: values.paymentMethod,
                  payPalEmail: values.payPalEmail,
                },
              }),
            }
          );
          response = data;
        } else {
          const { data } = await axiosInstance.patch(
            `/users/updateOne`,
            {
              ...values,
              ...(values.paymentMethod === "other" && {
                paymentInfo: {
                  paymentMethod: values.paymentMethod,
                  value: values.textFieldOther,
                },
              }),
              ...(values.paymentMethod === "bankTransfer" && {
                paymentInfo: {
                  paymentMethod: values.paymentMethod,
                  phoneNumber: values.phoneBankTransfer,
                  email: values.emailBankTransfer,
                  address: values.addressBankTransfer,
                  zipCode: values.zipCodeBankTransfer,
                  bankName: values.bankNameBankTransfer,
                  fullName: values.fullNameBankTransfer,
                  iban: values.accountNumberBankTransfer,
                },
              }),
              ...(values.paymentMethod === "payPal" && {
                paymentInfo: {
                  paymentMethod: values.paymentMethod,
                  payPalEmail: values.payPalEmail,
                },
              }),
            },

            {
              params: {
                userId: innerProps?.userData._id,
              },
            }
          );
          response = data;
        }
      } else {
        const url =
          innerProps.role === "company" ? `/companies` : `/users/createOne`;

        const { data } = await axiosInstance.post(url, values);
        response = data;
      }

      if (response.status === "warning") {
        return toast.custom(<ToastWarning text={response.message} />);
      } else {
        toast.success(response.message);

        userDataForm.reset();
        if (innerProps?.onTrigger) {
          innerProps.onTrigger();
        }

        modals.closeAll();
      }
    } catch (err) {
      console.log(err);

      toast.error(err.message || err.response.data.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form
      className={styles.editWorkerForm}
      onSubmit={userDataForm.onSubmit((values) => toCreateOrUpdateUser(values))}
    >
      <Grid
        gutter="lg"
        grow
        columns={12}
        className={clsx([styles.formGridBlock, styles.main])}
      >
        {innerProps?.role === "company" ? (
          <>
            <Grid.Col span={12}>
              <TextInput
                placeholder="Company name"
                label="Company name"
                withAsterisk
                {...userDataForm.getInputProps("name")}
              />
            </Grid.Col>
            <Grid.Col span={12}>
              <TextInput
                label="Email"
                withAsterisk
                {...userDataForm.getInputProps("email")}
              />
            </Grid.Col>
            {innerProps?.mode !== "edit" && (
              <Grid.Col span={12}>
                <TextInput
                  label="Password"
                  type="password"
                  withAsterisk
                  {...userDataForm.getInputProps("password")}
                />
              </Grid.Col>
            )}

            <Grid.Col span={6}>
              <NumberInput
                placeholder="Percentage"
                label="Percentage"
                {...userDataForm.getInputProps("percentage")}
                icon="%"
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <Select
                label="Payment Method"
                placeholder="Select payment method"
                data={[
                  { value: "payPal", label: "PayPal" },
                  { value: "bankTransfer", label: "Bank Transfer" },
                  { value: "other", label: "Other" },
                ]}
                {...userDataForm.getInputProps("paymentMethod")}
              />
            </Grid.Col>
            {userDataForm.values.paymentMethod === "payPal" && (
              <Grid.Col span={12}>
                <TextInput
                  label="PayPal Email"
                  placeholder="Enter your PayPal email"
                  {...userDataForm.getInputProps("payPalEmail")}
                />
              </Grid.Col>
            )}
            {userDataForm.values.paymentMethod === "bankTransfer" && (
              <>
                <Grid.Col span={6}>
                  <TextInput
                    label="Phone"
                    placeholder="Enter your phone number"
                    {...userDataForm.getInputProps("phoneBankTransfer")}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <TextInput
                    label="Email"
                    placeholder="Enter your email"
                    {...userDataForm.getInputProps("emailBankTransfer")}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <TextInput
                    label="Address"
                    placeholder="Enter your address"
                    {...userDataForm.getInputProps("addressBankTransfer")}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <TextInput
                    label="Zip Code"
                    placeholder="Enter your zip code"
                    {...userDataForm.getInputProps("zipCodeBankTransfer")}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <TextInput
                    label="Bank Name"
                    placeholder="Enter your bank name"
                    {...userDataForm.getInputProps("bankNameBankTransfer")}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <TextInput
                    label="Full Name"
                    placeholder="Enter your full name"
                    {...userDataForm.getInputProps("fullNameBankTransfer")}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <TextInput
                    label="Account Number"
                    placeholder="Enter your account number"
                    {...userDataForm.getInputProps("accountNumberBankTransfer")}
                  />
                </Grid.Col>
              </>
            )}
            {userDataForm.values.paymentMethod === "other" && (
              <Grid.Col span={12}>
                <TextInput
                  label="Other Payment Method Details"
                  placeholder="Enter details for the other payment method"
                  {...userDataForm.getInputProps("textFieldOther")}
                />
              </Grid.Col>
            )}
          </>
        ) : (
          <>
            {(userDataForm.values.role !== "author" ||
              (userDataForm.values.role === "author" &&
                innerProps?.mode === "create")) && (
              <>
                <Grid.Col span={12} className={styles.gridCol}>
                  <Select
                    data={roleVariants}
                    placeholder="Role"
                    label="Role"
                    {...userDataForm.getInputProps("role")}
                    dropdownPosition="bottom"
                  />
                </Grid.Col>
                <Grid.Col span={6} className={styles.gridCol}>
                  <TextInput
                    placeholder="Position"
                    label="Position"
                    {...userDataForm.getInputProps("position")}
                  />
                </Grid.Col>
              </>
            )}
            <Grid.Col span={6} className={styles.gridCol}>
              <TextInput
                placeholder="Name"
                label="Name"
                withAsterisk
                {...userDataForm.getInputProps("name")}
              />
            </Grid.Col>
            <Grid.Col span={6} className={styles.gridCol}>
              <TextInput
                placeholder="Email"
                label="Email"
                withAsterisk
                {...userDataForm.getInputProps("email")}
              />
            </Grid.Col>

            {(userDataForm.values.role === "stringer" ||
              userDataForm.values.role === "researcher") && (
              <Grid.Col span={6} className={styles.gridCol}>
                <NumberInput
                  label="Telegram id"
                  placeholder="Telegram id"
                  {...userDataForm.getInputProps("telegramId")}
                  hideControls
                />
              </Grid.Col>
            )}

            {innerProps?.mode !== "edit" &&
              userDataForm.values.role !== "author" && (
                <Grid.Col span={6} className={styles.gridCol}>
                  <TextInput
                    placeholder="Password"
                    label="Password"
                    {...userDataForm.getInputProps("password")}
                  />
                </Grid.Col>
              )}
            {userDataForm.values.role !== "author" &&
              userDataForm.values.role !== "observer" &&
              userDataForm.values.role !== "stringer" && (
                <>
                  <Grid.Col span={6} className={styles.gridCol}>
                    <TextInput
                      placeholder="Nickname"
                      label="Nickname"
                      {...userDataForm.getInputProps("nickname")}
                      icon="@"
                    />
                  </Grid.Col>
                  <Grid.Col span={6} className={styles.gridCol}>
                    <NumberInput
                      placeholder={
                        userDataForm.values.role === "researcher"
                          ? "Advance payment exclusive"
                          : "Advance payment"
                      }
                      label={
                        userDataForm.values.role === "researcher"
                          ? "Advance payment exclusive"
                          : "Advance payment"
                      }
                      {...userDataForm.getInputProps("advancePayment")}
                      icon="$"
                    />
                  </Grid.Col>
                  {userDataForm.values.role === "researcher" && (
                    <>
                      <Grid.Col span={6} className={styles.gridCol}>
                        <NumberInput
                          placeholder="Advance payment non-exclusive"
                          label="Advance payment non-exclusive"
                          {...userDataForm.getInputProps(
                            "advancePaymentNonExclusive"
                          )}
                          icon="$"
                        />
                      </Grid.Col>
                    </>
                  )}
                  <Grid.Col span={6} className={styles.gridCol}>
                    <NumberInput
                      placeholder="Percentage"
                      label="Percentage"
                      {...userDataForm.getInputProps("percentage")}
                      icon="%"
                    />
                  </Grid.Col>
                  <Grid.Col
                    span={6}
                    className={clsx([styles.gridCol, styles.marginBottomLg])}
                  >
                    <Select
                      data={countries}
                      searchable
                      placeholder="Country"
                      label="Country"
                      {...userDataForm.getInputProps("country")}
                    />
                  </Grid.Col>
                </>
              )}

            {(userDataForm.values.role === "stringer" ||
              userDataForm.values.role === "researcher") && (
              <Grid.Col
                span={12}
                className={clsx([styles.gridCol, styles.marginBottomLg])}
              >
                <Grid
                  gutter="lg"
                  grow
                  columns={12}
                  className={clsx([styles.formGridBlock, styles.paymentInfo])}
                >
                  <Grid.Col
                    span={!userDataForm.values.stringerPaymentType ? 12 : 6}
                    className={styles.gridCol}
                  >
                    <Select
                      w="100%"
                      placeholder="Stringer payment type"
                      data={[
                        {
                          value: "advance",
                          label: "Advance",
                          disabled: Boolean(
                            userDataForm.values.role === "researcher"
                          ),
                        },
                        { value: "percentage", label: "Percentage" },
                      ]}
                      {...userDataForm.getInputProps("stringerPaymentType")}
                      clearable
                      dropdownPosition="bottom"
                    />
                  </Grid.Col>

                  {!!userDataForm.values.stringerPaymentType && (
                    <Grid.Col span={6} className={styles.gridCol}>
                      <NumberInput
                        icon={
                          userDataForm.values.stringerPaymentType ===
                          "percentage"
                            ? "%"
                            : "$"
                        }
                        placeholder={
                          userDataForm.values.stringerPaymentType ===
                          "percentage"
                            ? "Percentage value"
                            : "Advance value"
                        }
                        {...userDataForm.getInputProps("stringerPaymentValue")}
                        hideControls
                        min={0}
                        max={
                          userDataForm.values.stringerPaymentType ===
                          "percentage"
                            ? 100
                            : 10000
                        }
                      />
                    </Grid.Col>
                  )}
                </Grid>
              </Grid.Col>
            )}

            {(userDataForm.values.role === "stringer" ||
              userDataForm.values.role === "researcher" ||
              userDataForm.values.role === "author") && (
              <Grid.Col
                span={12}
                className={clsx([styles.gridCol, styles.marginBottomLg])}
              >
                <Grid
                  gutter="lg"
                  grow
                  columns={12}
                  className={clsx([styles.formGridBlock, styles.paymentInfo])}
                >
                  <Grid.Col span={12} className={styles.gridCol}>
                    <Select
                      placeholder="Choose payment method"
                      data={paymentVariants}
                      {...userDataForm.getInputProps("paymentMethod")}
                      clearable
                      dropdownPosition="bottom"
                    />
                  </Grid.Col>
                  {userDataForm.values.paymentMethod === "bankTransfer" && (
                    <>
                      <Grid.Col span={6} className={styles.gridCol}>
                        <NumberInput
                          withAsterisk
                          placeholder="Phone number"
                          label="Phone number"
                          hideControls
                          {...userDataForm.getInputProps("phoneBankTransfer")}
                        />
                      </Grid.Col>
                      <Grid.Col span={6} className={styles.gridCol}>
                        <TextInput
                          withAsterisk
                          placeholder="Email"
                          label="Email"
                          {...userDataForm.getInputProps("emailBankTransfer")}
                        />
                      </Grid.Col>
                      <Grid.Col span={6} className={styles.gridCol}>
                        <TextInput
                          withAsterisk
                          placeholder="Address"
                          label="Address"
                          {...userDataForm.getInputProps("addressBankTransfer")}
                        />
                      </Grid.Col>
                      <Grid.Col span={6} className={styles.gridCol}>
                        <TextInput
                          withAsterisk
                          placeholder="Zip code"
                          label="Zip code"
                          {...userDataForm.getInputProps("zipCodeBankTransfer")}
                        />
                      </Grid.Col>
                      <Grid.Col span={6} className={styles.gridCol}>
                        <TextInput
                          withAsterisk
                          placeholder="Bank name"
                          label="Bank name"
                          {...userDataForm.getInputProps(
                            "bankNameBankTransfer"
                          )}
                        />
                      </Grid.Col>
                      <Grid.Col span={6} className={styles.gridCol}>
                        <TextInput
                          withAsterisk
                          placeholder="Full name"
                          label="Full name"
                          {...userDataForm.getInputProps(
                            "fullNameBankTransfer"
                          )}
                        />
                      </Grid.Col>
                      <Grid.Col span={12} className={styles.gridCol}>
                        <TextInput
                          withAsterisk
                          placeholder="IBAN"
                          label="IBAN"
                          {...userDataForm.getInputProps(
                            "accountNumberBankTransfer"
                          )}
                        />
                      </Grid.Col>
                      <Grid.Col span={12} className={styles.gridCol}>
                        <TextInput
                          placeholder="Additional information"
                          label="AdditionalInformation"
                          {...userDataForm.getInputProps(
                            "AdditionalInformation"
                          )}
                        />
                      </Grid.Col>
                    </>
                  )}
                  {userDataForm.values.paymentMethod === "payPal" && (
                    <Grid.Col span={6} className={styles.gridCol}>
                      <TextInput
                        withAsterisk
                        placeholder="PayPal email"
                        label="PayPal email"
                        {...userDataForm.getInputProps("payPalEmail")}
                      />
                    </Grid.Col>
                  )}
                  {userDataForm.values.paymentMethod === "other" && (
                    <Grid.Col span={6} className={styles.gridCol}>
                      <TextInput
                        withAsterisk
                        placeholder="Payment data is arbitrary"
                        label="Payment data is arbitrary"
                        {...userDataForm.getInputProps("textFieldOther")}
                      />
                    </Grid.Col>
                  )}
                </Grid>
              </Grid.Col>
            )}

            {(userDataForm.values.role === "stringer" ||
              userDataForm.values.role === "researcher") && (
              <Grid.Col
                span={12}
                className={clsx([styles.gridCol, styles.marginBottomXs])}
              >
                <Checkbox
                  label="Can be assigned"
                  {...userDataForm.getInputProps("canBeAssigned")}
                  checked={userDataForm.values.canBeAssigned}
                  color="cyan"
                />
              </Grid.Col>
            )}
            {userDataForm.values.role !== "author" && (
              <Grid.Col
                span={12}
                mt="sm"
                className={clsx([styles.gridCol, styles.marginBottomXs])}
              >
                <Checkbox
                  label="Display on the site"
                  {...userDataForm.getInputProps("displayOnTheSite")}
                  checked={userDataForm.values.displayOnTheSite}
                  color="cyan"
                />
              </Grid.Col>
            )}
            {userDataForm.values.role === "researcher" && (
              <Grid.Col
                span={12}
                mt="sm"
                className={clsx([styles.gridCol, styles.marginBottomLg])}
              >
                <Checkbox
                  label="Video pool"
                  {...userDataForm.getInputProps("videoPool")}
                  checked={userDataForm.values.videoPool}
                  color="cyan"
                />
              </Grid.Col>
            )}
          </>
        )}
        <Grid.Col span={12} className={styles.gridCol}>
          <Button
            disabled={isLoading}
            color="grape"
            type="submit"
            className={styles.saveBtn}
            loading={isLoading}
          >
            {innerProps?.mode === "create" ? "Create" : "Save"}
          </Button>
        </Grid.Col>
      </Grid>
    </form>
  );
};

export default UserInfoModal;
