import React, { useMemo, useState } from "react";

import { purple, grey, cyan } from "@material-ui/core/colors";

import { ThemeContext } from "../lib/ThemeContext";

import { CssBaseline, createTheme } from "@material-ui/core";

import { ThemeProvider as MuiThemeProvider } from "@material-ui/styles";

import { MantineProvider as MantineThemeProvider } from "@mantine/core";

const ThemeProvider = (props) => {
  const { children } = props;

  const [mode, setMode] = useState("light");

  const getDesignTokens = (mode) => ({
    palette: {
      mode,

      ...(mode === "light"
        ? {
            primary: {
              main: purple[300],
              light: purple[800],
              dark: purple[500],
              contrastText: grey[200],
            },
            secondary: {
              main: grey[100],
              light: grey[800],
              dark: grey[500],

              contrastText: grey[200],
            },
            error: {
              main: grey[800],
            },

            background: {
              default: grey[100],
              paper: grey[100],
            },
            text: {
              primary: grey[900],
              secondary: grey[800],
            },
          }
        : {
            primary: {
              main: cyan[300],
            },
            secondary: {
              main: grey[900],
            },

            error: {
              main: grey[200],
            },

            background: {
              default: "black",
              paper: grey[900],
            },
            text: {
              primary: "#fff",
              secondary: grey[500],
            },
          }),
    },
  });

  const theme = useMemo(() => createTheme(getDesignTokens(mode)), [mode]);

  const defaultProps = useMemo(
    () => ({
      mode,
      setMode,
    }),
    [mode]
  );

  return (
    <ThemeContext.Provider value={defaultProps}>
      <MuiThemeProvider theme={theme}>
        <MantineThemeProvider
          theme={{ colorScheme: mode }}
          // withNormalizeCSS
          // withGlobalStyles
        >
          <CssBaseline />
          {children}
        </MantineThemeProvider>
      </MuiThemeProvider>
    </ThemeContext.Provider>
  );
};

export default ThemeProvider;
