import React, { memo, useCallback, useContext, useState } from "react";

import { Autocomplete, Button, Select, TextInput } from "@mantine/core";

import styles from "./styles.module.scss";
import { useForm } from "@mantine/form";

const FeedsManageModal = memo(({ innerProps }) => {
  const [isRequested, setIsRequested] = useState(false);

  const feedForm = useForm({
    initialValues: {
      name: innerProps.hasOwnProperty("data") ? innerProps.data.name : "",
      group: innerProps.hasOwnProperty("data") ? innerProps.data.group : "",
      filename: innerProps.hasOwnProperty("data")
        ? innerProps.data.filename
        : "",
    },

    validate: {
      name: (value) => (!value ? "Empty field" : null),

      filename: (value) =>
        !value
          ? "Empty field"
          : value.includes(".")
          ? "The file name must be without an extension"
          : null,
    },
  });

  const onSubmitForm = useCallback(async () => {
    try {
      setIsRequested(true);

      if (innerProps.hasOwnProperty("onSubmit")) {
        await innerProps.onSubmit(feedForm.values);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsRequested(false);
    }
  }, [innerProps, setIsRequested, feedForm]);

  const onDisabledBtn = useCallback(async () => {
    if (!feedForm.values.name || !feedForm.values.filename) {
      return true;
    } else {
      return false;
    }
  }, [feedForm.values]);

  const onGetGroupList = useCallback(() => {
    return [
      ...new Set(
        innerProps.feedList
          .map((obj) => {
            return obj.group;
          })
          .filter((el) => el)
      ),
    ];
  }, [innerProps]);

  return (
    <div className={styles.modalWrapper}>
      <form onSubmit={feedForm.onSubmit((values) => onSubmitForm(values))}>
        <TextInput
          {...feedForm.getInputProps("name")}
          label="Name"
          placeholder="For example, 'categoryAnimals'"
          required
        />
        <TextInput
          label="File name"
          {...feedForm.getInputProps("filename")}
          placeholder="For example, 'mrssAnimals'"
          required
        />
        <Autocomplete
          label="Group"
          placeholder="For multi-status change"
          data={onGetGroupList()}
          {...feedForm.getInputProps("group")}
          color="cyan"
          clearable
        />
        <Button
          gradient={{ from: "grape", to: "cyan", deg: 90 }}
          variant="gradient"
          className={styles.submitBtn}
          type="submit"
          disabled={onDisabledBtn}
          loading={isRequested}
        >
          Send
        </Button>
      </form>
    </div>
  );
});

export default FeedsManageModal;
