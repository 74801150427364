import React, { useState, useRef } from "react";
import { Table, createStyles, rem, Box, Flex, Avatar } from "@mantine/core";

import styles from "./styles.module.scss";
import ScrollAreaLayout from "../../../widgets/ScrollAreaLayout";

import clsx from "clsx";
import EmptyNotice from "../EmptyNotice";
import { IconCoinOff } from "@tabler/icons-react";

const QueueVideoModal = ({ innerProps: { body, headers } }) => {
  const [scrolled, setScrolled] = useState(false);

  const theadMainRef = useRef(null);

  const useStyles = createStyles((theme) => ({
    header: {
      position: "sticky",
      top: 0,
      backgroundColor:
        theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white,
      transition: "box-shadow 150ms ease",

      "&::after": {
        content: '""',
        position: "absolute",
        left: 0,
        right: 0,
        bottom: 0,
        borderBottom: `${rem(1)} solid ${
          theme.colorScheme === "dark"
            ? theme.colors.dark[3]
            : theme.colors.gray[2]
        }`,
      },
    },

    scrolled: {
      boxShadow: theme.shadows.sm,
    },
  }));

  const { classes, cx } = useStyles();
  return (
    <div className={styles.modal}>
      <Box className={styles.tableWrapper}>
        {!body?.length ? (
          <Flex
            justify="center"
            align="center"
            className={styles.emptyNoticeBlock}
          >
            <EmptyNotice
              icon={<IconCoinOff size="25px" color="black" />}
              text="It's empty here yet"
              color="black"
            />
          </Flex>
        ) : (
          <ScrollAreaLayout setScrolled={setScrolled} height={450}>
            <Table withColumnBorders withBorder className={styles.table}>
              <thead
                ref={theadMainRef}
                className={cx(classes.header, {
                  [classes.scrolled]: scrolled,
                })}
              >
                <tr>
                  {headers?.map((obj) => (
                    <th
                      className={clsx(
                        obj.value.toLowerCase().includes("your") &&
                          styles.colorize
                      )}
                      key={obj.value}
                      colSpan={obj.colSpan}
                    >
                      {obj.value}
                    </th>
                  ))}
                </tr>
              </thead>
              <thead
                className={clsx([
                  cx({
                    [classes.scrolled]: scrolled,
                  }),
                ])}
                style={{ top: theadMainRef?.current?.clientHeight }}
              ></thead>

              <tbody>
                {body?.map((obj, index) =>
                  obj.videoData ? (
                    <tr key={index}>
                      <td>{obj.videoData.videoData.videoId}</td>
                      <td>
                        <Avatar
                          src={obj.videoData.bucket.cloudScreenLink}
                          variant="rounded"
                        ></Avatar>
                      </td>
                      <td>{obj.videoData.videoData.title}</td>

                      <td>
                        {obj.videoData.uploadedToFb ? "" : "facebook"}
                        {!obj.videoData.uploadedToFb &&
                        !obj.videoData.uploadedToYoutube
                          ? ", "
                          : ""}
                        {obj.videoData.uploadedToYoutube ? "" : "youtube"}
                      </td>
                    </tr>
                  ) : (
                    <></>
                  )
                )}
              </tbody>
            </Table>
          </ScrollAreaLayout>
        )}
      </Box>
    </div>
  );
};

export default QueueVideoModal;
