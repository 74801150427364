import React, { memo, useCallback, useState } from "react";

import axiosInstance from "../../../api/axios.instance";

import styles from "./styles.module.scss";
import {
  Autocomplete,
  Box,
  Button,
  Flex,
  RangeSlider,
  Select,
  Text,
  rem,
} from "@mantine/core";

import clsx from "clsx";

import { categoryVariants } from "../../../consts/videoCategoryVariants";

import ToastWarning from "../../../toast/components/toastWarning/ToastWarning";

import { useForm } from "@mantine/form";
import toast from "react-hot-toast";
import { modals } from "@mantine/modals";

const VideoCompilationModal = memo(() => {
  const [isRequested, setIsRequested] = useState(false);

  const videoCompilationForm = useForm({
    initialValues: {
      quantity: "",
      duration: [300, 300],
      category: "",
    },
  });

  const onGenerateVideoCompilation = useCallback(
    async (values) => {
      setIsRequested(true);

      const { duration, quantity, category } = values;

      const sendDuration = new Set(duration).size === duration.length;

      const payload = {
        ...(sendDuration && {
          duration: values.duration,
        }),
        ...(!!quantity && {
          quantity: +values.quantity,
        }),
        ...(!!category && {
          category: values.category,
        }),
      };

      try {
        const { data } = await axiosInstance.post(
          "/video/compilation",
          payload
        );

        switch (data.status) {
          case "warning":
            toast.custom(<ToastWarning text={data.message} />);
            break;
          case "success":
            modals.closeAll();
            toast.success(data.message);
            break;
        }
      } catch (err) {
        console.log(err);
      } finally {
        setIsRequested(false);
      }
    },
    [axiosInstance]
  );

  return (
    <form
      onSubmit={videoCompilationForm.onSubmit(onGenerateVideoCompilation)}
      className={styles.form}
      style={{margin: '5%'}}

    >
      <Flex direction="column" gap="xs">
        <Box className={styles.inputBox}>
          <Autocomplete
            label="Number of sales"
            placeholder="30"
            data={["5", "10", "15", "20", "25", "30"]}
            color="cyan"
            clearable
            size="xs"
            {...videoCompilationForm.getInputProps("quantity")}
          />
        </Box>
        <Box className={clsx([styles.inputBox, styles.flexDirection])}>
          <Text fw={500} size="xs">
            Duration range
          </Text>
          <RangeSlider
            size="xs"
            color="cyan"
            min={0}
            max={300}
            labelAlwaysOn
            showLabelOnHover={false}
            thumbSize={7}
            defaultValue={[300, 300]}
            styles={{ thumb: { borderWidth: rem(2), padding: rem(3) } }}
            {...videoCompilationForm.getInputProps("duration")}
          />
        </Box>
        <Box className={styles.inputBox}>
          <Select
            label="Video category"
            color="cyan"
            placeholder="Animals"
            data={categoryVariants}
            size="xs"
            clearable
            {...videoCompilationForm.getInputProps("category")}
          />
        </Box>
        <Button
          w="100%"
          disabled={isRequested}
          loading={isRequested}
          variant="gradient"
          gradient={{ from: "cyan", to: "violet" }}
          type="submit"
        >
          <Flex align="center" gap="5px">
            Generate and upload
          </Flex>
        </Button>
      </Flex>
    </form>
  );
});

export default VideoCompilationModal;
