import React, { useMemo, useState } from "react";

import { UserContext } from "../lib/UserContext";

const UserProvider = (props) => {
  const { children } = props;

  const [currentUser, setCurrentUser] = useState({});

  //   const defaultProps = useMemo(
  //     () => ({
  //       currentUser,
  //       setCurrentUser,
  //     }),
  //     [currentUser, setCurrentUser]
  //   );

  return (
    <UserContext.Provider
      currentUser={currentUser}
      setCurrentUser={setCurrentUser}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserProvider;
