const categoryVariants = [
  "Animals",
  "Fails and Funnies",
  "News",
  "Accidents",
  "Road accidents",
  "Weather",
  "Cool",
  "Rescue",
  "Sport",
  "Heartwarming",
];

export { categoryVariants };
