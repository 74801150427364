import React, { useCallback, useState, useRef } from "react";
import { Table, createStyles, rem, Box, Tooltip, Flex } from "@mantine/core";

import styles from "./styles.module.scss";
import ScrollAreaLayout from "../../../widgets/ScrollAreaLayout";
import { leaveAfterComma } from "../../../utils/leaveAfterComma";

import clsx from "clsx";
import EmptyNotice from "../EmptyNotice";
import { IconCoinOff } from "@tabler/icons-react";

const SaleTableModal = ({ innerProps }) => {
  const [scrolled, setScrolled] = useState(false);

  const theadMainRef = useRef(null);

  const useStyles = createStyles((theme) => ({
    header: {
      position: "sticky",
      top: 0,
      backgroundColor:
        theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white,
      transition: "box-shadow 150ms ease",

      "&::after": {
        content: '""',
        position: "absolute",
        left: 0,
        right: 0,
        bottom: 0,
        borderBottom: `${rem(1)} solid ${
          theme.colorScheme === "dark"
            ? theme.colors.dark[3]
            : theme.colors.gray[2]
        }`,
      },
    },

    scrolled: {
      boxShadow: theme.shadows.sm,
    },
  }));

  const { classes, cx } = useStyles();

  const onTitleClick = useCallback((videoId) => {
    window.open(`/library/${videoId}`, "_blank");
  }, []);

  return (
    <div className={styles.modal}>
      <Box className={styles.tableWrapper}>
        {!innerProps?.tableData?.body?.length ? (
          <Flex
            justify="center"
            align="center"
            className={styles.emptyNoticeBlock}
          >
            <EmptyNotice
              icon={<IconCoinOff size="25px" color="black" />}
              text="It's empty here yet"
              color="black"
            />
          </Flex>
        ) : (
          <ScrollAreaLayout setScrolled={setScrolled} height={450}>
            <Table withColumnBorders withBorder className={styles.table}>
              <thead
                ref={theadMainRef}
                className={cx(classes.header, {
                  [classes.scrolled]: scrolled,
                })}
              >
                <tr>
                  {innerProps?.tableData?.headers?.map((obj) => (
                    <th
                      className={clsx(
                        obj.value.toLowerCase().includes("your") &&
                          styles.colorize
                      )}
                      key={obj.value}
                      colSpan={obj.colSpan}
                    >
                      {obj.value}
                    </th>
                  ))}
                </tr>
              </thead>
              <thead
                className={clsx([
                  cx({
                    [classes.scrolled]: scrolled,
                  }),
                ])}
                style={{ top: theadMainRef?.current?.clientHeight }}
              >
                <tr>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th>Amount</th>
                  <th>Received</th>
                </tr>
              </thead>
              <tbody>
                {innerProps?.tableData?.body?.map((obj, index) => (
                  <tr key={index}>
                    <td>{obj.date}</td>
                    <td>{obj.company}</td>
                    <td>{obj.countSales}</td>
                    <td
                      className={styles.cursorPointer}
                      onClick={() => onTitleClick(obj.videoId)}
                    >
                      <Tooltip.Floating label={obj.videoTitle}>
                        <p>
                          {obj.videoTitle.length <= 25
                            ? obj.videoTitle
                            : `${obj.videoTitle.substring(0, 25)}...`}
                        </p>
                      </Tooltip.Floating>
                    </td>
                    <td>{!!obj.researchers ? obj.researchers : "-"}</td>
                    <td>
                      {leaveAfterComma(obj.earnings.total, 2)}
                      <span className={clsx([styles.gray])}> $</span>
                    </td>
                    <td>
                      {!!obj.earnings.own
                        ? leaveAfterComma(obj.earnings.own, 2)
                        : "-"}
                      {!!obj.earnings.own && (
                        <span className={clsx([styles.gray])}> $</span>
                      )}
                    </td>
                    <td>{!!obj.received ? "yes" : "no"}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </ScrollAreaLayout>
        )}
      </Box>
    </div>
  );
};

export default SaleTableModal;
